import React from 'react'
import { useState, useEffect } from 'react';
import Product from '../components/Product';
import Spinner from '../components/Spinner';

const Home = () => {

  const API_URL = 'https://theosnovatel.com/api/shop/get-items/';
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  async function fetchProductData() {
    setLoading(true);

    try{
        const res = await fetch(API_URL);
        const data = await res.json().then( (data) => setItems(data.data));

        
    }
    catch(error) {
        console.log("Error fetching product");
        setItems([]);
    }   
    setLoading(false);
  }

  useEffect( () => {
    fetchProductData();
  },[])

  return (
    <div className='screen-1 '>
      <div className="screen-1-wrapper ">
        {
          loading ? <Spinner /> : 
          items.length > 0 ? 
          (<div className="">
              {
                items.map( (item) => (
                    <Product key = { item.id } item={item} />
                  ) )
              }
            <div style={{height:'150px'}}></div>
          </div>) : 
        
        <div className="flex justify-center items-center "> 
            <p className='text-gray-700 font-semibold text-lg text-left truncate w-40 mt-[10%]'>No Data Found!</p>
            
          </div>

        }

        <hr />

        {/* <div className="footer ">
          <div>
            ООО «Форвард»  <br />
            ИНН: 9715295750<br />
            ОГРН: 1177746242915
          </div>
          <div>
            <a className='link' href="https://theosnovatel.com/static-files/offert.pdf" target="_blank">Публичная оферта</a> <br />
            <a className='link' href="https://theosnovatel.com/static-files/conf.pdf" target="_blank">Политика конфиденциальности</a> <br />
            <a className='link' target='_blank' href="https://t.me/Swest_e">Служба заботы</a> <br />
          </div>
        </div> */}

      </div>
      
    </div>
  );
}

export default Home